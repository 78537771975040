.loginForm {
    background: #fff;
    position: relative;
    width: 824px;
    height: 400px;
    border-radius: var(--border-radius);
    margin: 0 auto;
    top: 50vh;
    margin-top: -200px;
}

.loginForm.signup {
    height: 820px;
    margin-top: -380px;
}

.loginForm .left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    background: var(--grey-light);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.loginForm .maintitle, .loginForm .left .title {
    color: var(--orange-accent);
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
}

.loginForm .maintitle {
    padding-top: 21px;
    padding-left: 20px;
}

.loginForm .left .subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color-black);
    position: absolute;
    left: 20px;
    bottom: 21px;
    text-transform: uppercase;
}

.loginForm .left .subtitle:not(.top):before {
    content: ' ';
    display: block;
    width: 40px;
    height: 4px;
    background: var(--orange-accent);
    margin-bottom: 10px;
}

.loginForm .left .subtitle.top {
    position: relative;
    left: auto;
    bottom: auto;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-transform: none;
    margin-top: 10px;
}

.loginForm .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    bottom: 0;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.loginForm .inner {
    padding: 21px 20px 21px 20px;
}

.loginForm .right .title {
    font-weight: 700;
    color: var(--text-color-black);
    font-size: 28px;
    line-height: 32px;
}

.loginForm .right .section-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.loginForm .lnkSignup {
    display: block;
    color: var(--orange-accent);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    top: 30px;
    right: 20px;
}

.loginForm .data {
    margin-top: 15px;
    margin-bottom: 30px;
}

.loginForm .data .cols-2 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.loginForm .data label {
    display: block;
    margin-bottom: 5px;
}

.loginForm .data > div:not(.psw_err) {
    margin-bottom: 15px;
}

.psw_err {
    color: var(--red);
    margin-top: -10px;
}

.loginForm .bottom {
    position: absolute;
    bottom: 21px;
    right: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginForm .bottom .social {
    display: flex;
    align-items: center;
    gap: 10px;
}

.loginForm .bottom .social label {
    color: var(--text-color-black);
    font-weight: 700;
}

.loginForm .message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginForm .message .pri {
    color: var(--text-color-black);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
}

.loginForm .message .sec {
    line-height: 20px;
    color: var(--text-color-black);
    text-align: center;
}
@import '../../assets/css/common';
@import '../../assets/css/common';

.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: var(--layout-width);
    margin: 0 auto;

    .sitename {
        display: block;
        color: var(--orange-accent);
        font-weight: 900;
        font-size: 28px;
        line-height: 32px;
        flex: 0 0 200px;
    }

    .topmenu {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        flex-grow: 0;
        gap: 10px;

        li {
            a {
                display: block;
                position: relative;
                color: var(--text-color-black);
                height: 52px;
                line-height: 52px;
                font-weight: 700;
                padding: 0 15px;

                &.active::before {
                    content: ' ';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 8px;
                    background: var(--orange-accent);
                }
            }
        }
    }

    .sep {
        flex-grow: 1;
    }

    .profile_menu {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .support {
        display: block;
        position: relative;
        margin-right: 20px;

        .btn_support {
            padding: 0 20px;

            &:hover {
                background: #D4D5D8;
            }
        }

        .support_msg {
            @include white-block;
            position: absolute;
            padding: 12px 16px;
            top: 105%;
            right: 0;
            width: 488px;
            box-shadow: 0px 3.2px 9px 0px #00000029;
            

            .btn_close {
                display: block;
                border: none;
                background: none;
                float: right;
            }

            p {
                margin: 0;

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.dlg_prices, .dlg_prices > div {
    //height: 720px;
    width: 1056px;
    max-width: none;
}

.dlg_prices {
    right: 50%;
    margin-right: var(--minus-half-layout-width);
}

.prices_wrap {
    display: flex;
    align-items: stretch;
    gap: 8px;

    .prices_col {
        background: #fff;
        border-radius: 16px;
        padding: 20px;
        flex: 0 0 264px;
        display: flex;
        flex-direction: column;

        &.orange {
            background: var(--orange-bg);
            flex: 0 0 200px;
        }

        .sep {
            flex-grow: 1;
        }

        .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 8px;

            &.white {
                color: #fff;
            }
        }

        .type {
            display: inline-block;
            line-height: 24px;
            padding: 0 10px;
            border-radius: 8px;
            font-size: 12px;

            &.grey {
                background: var(--grey-med);
            }

            &.orange {
                background: #feeee9;
            }
        }

        @mixin section {
            margin-top: 15px;

            label {
                font-weight: 700;
                line-height: 20px;
                margin: 0;
                color: var(--text-color-black);
            }

            small {
                display: block;
                font-size: 12px;
                line-height: 16px;
            }

            .checks, .arrows {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-top: 8px;

                li {
                    margin-bottom: 8px;
                    background-size: 16px 16px;
                    padding-left: 20px;
                    line-height: 16px;

                    .info {
                        float: right;
                    }
                }
            }

            .checks li {
                background: url(../../assets/img/list-check.svg) no-repeat top left;

                &.plus {
                    background: url(../../assets/img/list-plus.svg) no-repeat top left;
                }
            }

            .arrows li {
                background: url(../../assets/img/list-arrow.svg) no-repeat top left;
            }
        }

        .section1 {
            @include section;
            
            min-height: 170px;
        }

        .section2 {
            @include section;
            min-height: 140px;
        }

        .section3 {
            @include section;
        }

        .bottom {
            margin-top: 10px;
            
            button {
                width: 100%;
                padding: 0;
            }
        }

        .link_send_request {
            width: 100%;
            padding: 0;
            background: none;
            border: 1px solid #fff;
            color: #fff;
            display: inline-block;
            text-align: center;
            font-weight: 800;
            border-radius: var(--border-radius-btn);
            line-height: 32px;
        }
    }
}